// ** React import
import { Fragment } from "react";

// ** Sections import
import AHFooter from "./section/footer";
import AHAppBar from "./section/app-bar";
import AHHeroSection from "./section/hero-section";
import AHFeaturesSection from "./section/features-section";
import AHBackedBySection from "./section/backed-by-section";
import AHGamePlaySectionWrapper from "./section/game-play-section";
import AHGameSocialSection from "./section/game-social-section";
import AHGameInfoSection from "./section/archer-game-info-section";

function App() {
  return (
    <Fragment>
      {/* App Bar */}
      <AHAppBar />
      {/* Hero Section */}
      <AHHeroSection />
      {/* Backed By Section */}
      <AHBackedBySection />
      {/* Game Play Section */}
      <AHGamePlaySectionWrapper />
      {/* Game Social Section */}
      <AHGameSocialSection />
      {/* Features Section */}
      <AHFeaturesSection />
      {/* Game Info Section */}
      <AHGameInfoSection />
      {/* Footer */}
      <AHFooter />
    </Fragment>
  );
}

export default App;
