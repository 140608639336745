// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import SwiperProvider from "./SwiperContext";

// ** Components import
import ArcherHeroCard from "../../components/archer-hero-card";
import CharactersSlice from "../../components/character-slices";
import FeatureCard, { FeatureInfo } from "../../components/feature-card";

// ** Assets import
import NFTTradeBackground from "./images/nft-trade-bg.png";

// ** Css import
import "./styles.css";
import { AHAssets } from "../../assets";

const AH_FEATURES: FeatureInfo[] = [
  {
    title: "Truly On-Chain",
    description:
      "Seamless Web3 Integration allows players to truly own their in-game assets and benefit from a vibrant economy.",
  },
  {
    title: "Exclusive IP",
    description:
      "IP Characters add depth and value to game with unique backstory and special abilities, providing community with enjoyment.",
  },
  {
    title: "Fair Gameplay",
    description:
      "Ensures a fair and enjoyable experience for all players. The game's skill-based mechanics minimize the impact of the pay-to-win model.",
  },
];

const AHFeaturesSection = () => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleOpenTooltip = () => {
    setShowTooltip(true);
  };

  const handleCloseTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <SwiperProvider>
      <Box className="ah-features-section-container">
        <Box className="ah-features-section-content">
          {/* Features Info */}
          <Box className="features-info-container">
            <Box className="features-title-box">
              <Typography className="features-title">
                What Sets Us Apart
              </Typography>
              <Typography component="div" className="features-description">
                At Archer Hunter, we're committed to providing a unique and
                exceptional gaming experience. Our games are designed to provide
                for passionate gamers, connect with like-minded individuals.
              </Typography>
            </Box>
            <Box className="features-card-container">
              {AH_FEATURES.map((feature) => (
                <FeatureCard
                  key={`ah-feature-${feature.title}`}
                  feature={feature}
                />
              ))}
            </Box>
            <Box
              id="ah-download-section"
              className="features-section-download-link-container"
            >
              <Typography className="download-title ">Download on</Typography>
              <Box className="download-btn-box">
                <Box
                  component="button"
                  className="feature-section-download-btn-container orange-bg-shadow"
                >
                  <img
                    src={AHAssets.AHBtnLeftDecor}
                    loading="lazy"
                    alt=""
                    className="btn-decor-img"
                  />
                  <img
                    src={AHAssets.AHGooglePlay}
                    alt=""
                    className="feature-section-download-btn-icon"
                  />
                  <Typography className="feature-section-download-btn-text">
                    Google Play
                  </Typography>
                  <img
                    src={AHAssets.AHBtnRightDecor}
                    loading="lazy"
                    alt=""
                    className="btn-decor-img"
                  />
                </Box>
                <Box
                  component="button"
                  className="feature-section-download-btn-container orange-bg-shadow"
                >
                  <img
                    src={AHAssets.AHBtnLeftDecor}
                    loading="lazy"
                    alt=""
                    className="btn-decor-img"
                  />
                  <img
                    src={AHAssets.AHApple}
                    alt=""
                    className="feature-section-download-btn-icon"
                  />
                  <Typography className="feature-section-download-btn-text">
                    App Store
                  </Typography>
                  <img
                    src={AHAssets.AHBtnRightDecor}
                    loading="lazy"
                    alt=""
                    className="btn-decor-img"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Characters Info */}
          <Box id="ah-gallery-section" className="character-info-container">
            <Box className="features-title-box">
              <Typography className="features-title">Characters</Typography>
              <Typography className="features-description">
                Archer Hunter Hero features over 6,000 unique NFTs with
                exclusive Intellectual Property (IP), categorized into 4
                distinct rarity tiers: Common, Rare, Epic, and Legendary.
              </Typography>
            </Box>
            <Box className="character-swiper-container">
              <CharactersSlice />
            </Box>
          </Box>
          {/* Archer Game Info */}
          <Box className="archer-hunter-info-container">
            <Box className="archer-info-box">
              <Typography className="features-left-title">
                Earn POWs Show Achievements
              </Typography>
              <Typography component="div" className="features-description-left">
                a valuable resource, unlocking the full potential of the game.
                The amount of POW represents your achievements in the game. As
                you accumulate POW, you will rank up and become a true Archer
                Hunter legend. The higher your rank, the more rewards and
                recognition you will receive.
              </Typography>
            </Box>
            <ArcherHeroCard />
          </Box>
        </Box>
        {/* NFT Trade Info */}
        <Box className="ah-nft-trade-info-container">
          <Box className="nft-trade-info-box">
            <img
              src={AHAssets.AHNftCardCharacter1}
              loading="lazy"
              alt=""
              className="nft-card-character-1-decor"
            />
            <img
              src={AHAssets.AHNftCardCharacter2}
              loading="lazy"
              alt=""
              className="nft-card-character-2-decor"
            />
            <img
              src={NFTTradeBackground}
              alt=""
              className="nft-info-card-bg-img"
            />
            <Box className="nft-trade-content">
              <Typography className="nft-card-title">NFTs Gallery</Typography>
              <Box className="nft-card-description-box">
                <Typography className="nft-card-description">
                  Archer Hunter provides valuable incentives for players,
                  balancing the economy and rewarding dedicated players through
                  NFTs.
                </Typography>
              </Box>
              <ClickAwayListener onClickAway={handleCloseTooltip}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleCloseTooltip}
                  open={showTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={<Typography>Coming soon</Typography>}
                >
                  <Box
                    component="button"
                    className="feature-section-play-now-btn-container orange-bg-shadow"
                    onClick={handleOpenTooltip}
                  >
                    <img
                      src={AHAssets.AHBtnLeftDecor}
                      loading="lazy"
                      alt=""
                      className="btn-decor-img"
                    />

                    <Typography className="feature-section-play-now-btn-text">
                      Explore now
                    </Typography>
                    <img
                      src={AHAssets.AHBtnRightDecor}
                      loading="lazy"
                      alt=""
                      className="btn-decor-img"
                    />
                  </Box>
                </Tooltip>
              </ClickAwayListener>
            </Box>
          </Box>
        </Box>
      </Box>
    </SwiperProvider>
  );
};

export default AHFeaturesSection;
