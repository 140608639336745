// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "./styles.css";

// ** Assets import
import { AHAssets } from "../../assets";
import AHBackedSectionTopSubtract from "./images/top-subtract.png";
import AHBackedSectionBottomSubtract from "./images/bottom-subtract.png";

const BACKED_LIST = [
  {
    id: "backed-by-sei",
    logo: AHAssets.AHSeiLogo,
    class: "sei-logo",
  },
  {
    id: "backed-by-nika",
    logo: AHAssets.AHNikaLogo,
    class: "nika-logo",
  },
  {
    id: "backed-by-allora",
    logo: AHAssets.AHAlloraLogo,
    class: "allora-logo",
  }
];

const AHBackedBySection = () => {
  return (
    <Box className="backed-by-section-container">
      <img
        src={AHBackedSectionTopSubtract}
        alt=""
        className="backed-by-section-top-subtract"
      />
      <Typography className="backed-by-section-title">POWERED BY</Typography>
      <Box className="backed-by-list">
        {BACKED_LIST.map((brand) => (
          <img key={brand.id} src={brand.logo} loading="lazy" alt="" className='brand-logo' />
        ))}
      </Box>
      <img
        src={AHBackedSectionBottomSubtract}
        alt=""
        className="backed-by-section-bottom-subtract"
      />
    </Box>
  );
};

export default AHBackedBySection;
