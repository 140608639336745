// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Hooks import
import { useSwiperContext } from "../../section/game-play-section/SwiperContent";

export interface GamePlayInfo {
  id: string;
  index: number;
  label: string;
  description?: string;
  icon?: string;
}

type GamePlayRowProps = {
  info: GamePlayInfo;
};

const GamePlayRow = ({ info }: GamePlayRowProps) => {
  const { swiper } = useSwiperContext();

  const handleSliceTo = (index: number) => {
    if (swiper) {
      swiper.slideTo(index)
    }
  };

  return (
    <Box className="game-play-row-container">
      <Box
        component="div"
        className="game-play-title-container"
        onClick={() => handleSliceTo(info.index)}
      >
        {info.icon ? (
          <img src={info.icon} className="pointer-img" alt="" />
        ) : (
          <Box className="hold-place-box" />
        )}
        <Typography className="ah-game-play-title">{info.label}</Typography>
      </Box>
      {info.description && (
        <Box className="ah-game-play-description-box">
          <Typography className="ah-game-play-description">
            {info.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GamePlayRow;
