// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Asset import
import { AHAssets } from "../../assets";
import ArcherInfoTopSubtract from "./images/game-info-top-subtract.png";
import ArcherInfoBottomSubtract from "./images/game-info-bottom-subtract.png";

// ** Css import
import "./styles.css";

const AHGameInfoSection = () => {
  return (
    <Box className="archer-game-info-container">
      <img
        src={ArcherInfoTopSubtract}
        className="archer-info-top-subtract"
        alt=""
      />
      <img
        alt=""
        src={AHAssets.AHGameInfoCharacter}
        className="archer-game-info-character-img"
      />
      <Box className="archer-game-info-overlay-box" />
      <Box className="archer-game-info-box">
        <Typography className="archer-game-info-title">
          What is Archer Hunter?
        </Typography>
        <Typography className="archer-game-info-description">
          Archer Hunter is an RPG game developed by Nika Labs in collaboration
          with Imba Global Studio, boasting millions of downloads on the App
          Store. Backed by Sei Network, Archer Hunter aims to become the first
          blockchain game with a proprietary IP publisher.
        </Typography>
      </Box>
      <img
        alt=""
        src={ArcherInfoBottomSubtract}
        className="archer-info-bottom-subtract"
      />
    </Box>
  );
};

export default AHGameInfoSection;
