// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Assets import
import { AHAssets } from "../../assets";
import SocialGameSectionTopSubtract from "./image/social-game-top-subtract.png";
import SocialGameSectionBottomSubtract from "./image/social-game-bottom-subtract.png";

// ** Css import
import "./styles.css";

const DISCORD_LINK = "https://discord.com/invite/R2DfvVXWzV"

const AHGameSocialSection = () => {
  return (
    <Box className="ah-game-social-section-container">
      <img
        src={SocialGameSectionTopSubtract}
        alt=""
        className="game-social-section-top-subtract"
      />
      <img
        src={AHAssets.AHAvatar1}
        loading="lazy"
        alt=""
        className="ah-game-social-section-avatar-1"
      />
      <img
        src={AHAssets.AHAvatar2}
        loading="lazy"
        alt=""
        className="ah-game-social-section-avatar-2"
      />
      <img
        src={AHAssets.AHAvatar3}
        loading="lazy"
        alt=""
        className="ah-game-social-section-avatar-3"
      />
      <Box className="ah-game-social-section-info-container">
        <Typography className="game-social-section-title">
          around the world
        </Typography>
        <Typography component="div" className="game-social-section-description">
          Team up with other players to tackle challenging quests and bosses.
          Get the latest news and updates about Archer Hunter.
        </Typography>
        <Box
          onClick={() => window.open(DISCORD_LINK, "_blank")}
          component="button"
          className="join-discord-btn-container orange-bg-shadow"
        >
          <img
            src={AHAssets.AHBtnLeftDecor}
            loading="lazy"
            alt=""
            className="btn-decor-img"
          />
          <Typography className="join-discord-btn-text">
            Join Our Discord
          </Typography>
          <img
            src={AHAssets.AHBtnRightDecor}
            loading="lazy"
            alt=""
            className="btn-decor-img"
          />
        </Box>
      </Box>
      <img
        src={AHAssets.AHAvatar4}
        loading="lazy"
        alt=""
        className="ah-game-social-section-avatar-4"
      />
      <img
        src={AHAssets.AHAvatar5}
        loading="lazy"
        alt=""
        className="ah-game-social-section-avatar-5"
      />
      <img
        src={AHAssets.AHAvatar6}
        loading="lazy"
        alt=""
        className="ah-game-social-section-avatar-6"
      />
      <img
        src={SocialGameSectionBottomSubtract}
        alt=""
        className="game-social-section-bottom-subtract"
      />
    </Box>
  );
};

export default AHGameSocialSection;
