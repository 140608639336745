import type {} from "@mui/material/themeCssVarsAugmentation";
import { ThemeOptions } from "@mui/material/styles";

import { PaletteMode } from "@mui/material";

import {
  brown,
  blueGrey,
  red,
  green,
  blue,
  orange,
} from "@mui/material/colors";

import "./font.css";

export const gray = {
    50: '#FBFCFE',
    100: '#EAF0F5',
    200: '#D6E2EB',
    300: '#BFCCD9',
    400: '#94A6B8',
    500: '#5B6B7C',
    600: '#4C5967',
    700: '#364049',
    800: '#131B20',
    900: '#090E10',
  };

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode: mode,
    background: {
      paper:  gray[50],
      default: "#fff",
    },
    primary: {
      main: "rgb(204,255,0)",
    },
    secondary: {
      main: blueGrey[500],
    },
    text: {
      primary: "#01020b",
      secondary: "rgba(0,0,0,0.6)",
      disabled: "rgba(0,0,0,0.38)",
      success: green[500],
      warning: orange[500],
      info: blue[500],
      error: red[500],
      brown: brown[700],
      contract: "var(--ah-black-color)",
    },
    divider: "rgb(150, 183, 16)",
  },
  typography: {
    fontFamily: [
      '"Alegreya SC", "Poppins", "Alegreya SC", "sans-serif"',
    ].join(","),
  },
  // https://mui.com/material-ui/customization/transitions/
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    // https://mui.com/material-ui/customization/transitions/#easings
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },
});

export default function getAHTheme(mode: PaletteMode): ThemeOptions {
    return {
      ...getDesignTokens(mode),
      components: {
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "1.25rem",
              color: brown[50],
              backgroundColor: brown[600],
            },
          },
        },
      },
    };
  }