// ** MUI import
import Box from "@mui/material/Box";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const Hero4Slice = () => {
  return (
    <Box className="ah-hero-slice-container">
      <img src={AHAssets.AHHero4Large} className="hero4-slice-img" alt="" />
    </Box>
  );
};

export default Hero4Slice;
