// ** react import
import { useMemo, useState, useCallback, Fragment } from "react";

// ** MUI Import
import Box from "@mui/material/Box";

// ** Hooks import
import { useSwiperContext } from "../../section/features-section/SwiperContext";

// ** Swiper components import
import type SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// ** Swiper css import
import "swiper/css";

// ** Slice import
import CharacterSlice, { CharacterInfo } from "./CharacterSlice";

// Css import
import "./styles.css";

// ** Assets import
import { AHAssets } from "../../assets";

export const AH_CHARACTERS: CharacterInfo[] = [
  {
    name: "Vixen",
    description:
      "A skilled archer with an unerring aim, found solace in the heart of the ancient forest. With unwavering courage, Vixen fought to protect their home.",
    image: AHAssets.AHVixenCharacter,
    imgClass: "vixen-character-img",
    prevImage: {
      src: AHAssets.AHSwiftCharacter,
    },
    nextImage: {
      src: AHAssets.AHKatherinePreview,
    },
  },
  {
    name: "Katherine",
    description:
      "Katherine is one of the supreme judges at the peak of Asgard with the highest belief in justice among all gods. she is the terror of the Demon tribe.",
    image: AHAssets.AHKatherineCharacter,
    prevImage: {
      src: AHAssets.AHVixenPreview,
      imgClass: "vixen-preview-image",
    },
    nextImage: {
      src: AHAssets.AHArgosPreview,
    },
  },
  {
    name: "Argos",
    description:
      "Lurking in the busy streets of Satin, there is always a ghost escaping, waiting to assassinate the dead prey. Argos, a Dark Elf assassin from the Arora Islands.",
    image: AHAssets.AHArgosCharacter,
    prevImage: {
      src: AHAssets.AHKatherinePreview,
    },
    nextImage: {
      src: AHAssets.AHSwiftPreview,
    },
  },
  {
    name: "Swift",
    description:
      "Swift was a Emerald beastman, and since he was young, he had a great relationship with all beasts and was considered the king of the forest, legend of the heroic beastman.",
    image: AHAssets.AHSwiftCharacter,
    prevImage: {
      src: AHAssets.AHArgosPreview,
    },
    nextImage: {
      src: AHAssets.AHVixenPreview,
      imgClass: "vixen-preview-image",
    },
  },
];

const CharactersSlice = () => {
  const [activeSliceIndex, setSlideIndex] = useState<number>(0);

  const { setSwiper, swiper } = useSwiperContext();

  const activeCharacter = useMemo(() => {
    // console.log("Active index: ", activeSliceIndex);
    return AH_CHARACTERS[activeSliceIndex];
  }, [activeSliceIndex]);

  const handlePrevSlice = useCallback(() => {
    if (activeSliceIndex === 0 && swiper) {
      swiper.slideTo(AH_CHARACTERS.length - 1);
      return;
    }

    if (swiper) {
      swiper.slidePrev();
    }
  }, [activeSliceIndex, swiper]);

  const handleNextSlice = useCallback(() => {
    if (activeSliceIndex === AH_CHARACTERS.length - 1 && swiper) {
      swiper.slideTo(0);
      return;
    }

    if (swiper) {
      swiper.slideNext();
    }
  }, [activeSliceIndex, swiper]);

  return (
    <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px', justifyContent:'center', justifyItems: 'center'}}>
      <Box
        component="div"
        className="navigate-slice-prev-btn"
        onClick={handlePrevSlice}
        sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', gap: '5px'}}
      >
        <img
          src={AHAssets.AHPointer}
          loading="lazy"
          alt=""
          className="prev-pointer-img"
        />
        {activeCharacter.prevImage && (
          <Box className="character-preview-box">
            <img
              alt=""
              src={activeCharacter.prevImage.src}
              className={`character-preview-image ${
                activeCharacter.prevImage.imgClass ?? ""
              }`}
            />
          </Box>
        )}
      </Box>
      <Swiper
        onSwiper={setSwiper}
        onBeforeDestroy={() => setSwiper(null)}
        centeredSlides={true}
        modules={[Autoplay]}
        onSlideChange={(swiper: SwiperCore) => {
          setSlideIndex(swiper.activeIndex);
        }}
      >
        
        {AH_CHARACTERS.map((character) => (
          <SwiperSlide key={`ah-character-${character.name}`}>
            <CharacterSlice info={character} />
          </SwiperSlide>
        ))}
        
      </Swiper>
      <Box
        component="div"
        className="navigate-slice-next-btn"
        onClick={handleNextSlice}
        sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', gap: '5px'}}
      >
        {activeCharacter.nextImage && (
          <Box className="character-preview-box">
            <img
              alt=""
              src={activeCharacter.nextImage.src}
              className={`character-preview-image ${
                activeCharacter.nextImage.imgClass ?? ""
              }`}
            />
          </Box>
        )}
        <img
          src={AHAssets.AHPointer}
          loading="lazy"
          alt=""
          className="next-pointer-img"
        />
      </Box>
    </Box>
  );
};

export default CharactersSlice;
