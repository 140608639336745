// ** MUI import
import Box from "@mui/material/Box";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const Hero5Slice = () => {
  return (
    <Box className="ah-hero-slice-container">
      <img src={AHAssets.AHHero5} className="hero5-slice-img" alt=""/>
    </Box>
  );
};

export default Hero5Slice;
