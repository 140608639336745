// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const ConnectButton = () => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleOpenTooltip = () => {
    setShowTooltip(true);
  };

  const handleCloseTooltip = () => {
    setShowTooltip(false);
  };

  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleCloseTooltip}
        open={showTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<Typography>Coming soon</Typography>}
      >
        <Box
          component="button"
          className="connect-btn-container orange-bg-shadow"
          onClick={handleOpenTooltip}
        >
          <img
            src={AHAssets.AHBtnLeftDecor}
            loading="lazy"
            alt=""
            className="btn-decor-img"
          />
          <Typography className="connect-btn-text">Connect Wallet</Typography>
          <img
            src={AHAssets.AHBtnRightDecor}
            loading="lazy"
            alt=""
            className="btn-decor-img"
          />
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default ConnectButton;
