// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import GamePlayRow from "./GamePlayRow";

// ** Type import
import { GamePlayInfo } from "./GamePlayRow";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const GAME_PLAYS: GamePlayInfo[] = [
  {
    id: "game-play-1",
    index: 0,
    label: "Chapter Mode",
    description:
      "The primary progression mode, where players journey through a series of chapters, facing increasingly challenging enemies and bosses. The game features three types of chapters: Dungeon Chapters, Arena Chapters, and Boss Chapters.",
    icon: AHAssets.AHPointer,
  },
  {
    id: "game-play-2",
    index: 1,
    label: "Infinite Tower",
  },
  {
    id: "game-play-3",
    index: 2,
    label: "Defense Mode",
  },
  {
    id: "game-play-4",
    index: 3,
    label: "Dual Boss",
  },
];

const AHGamePlay1 = () => {
  return (
    <Box className="ah-game-play1-container">
      <Box className="ah-game-play-info-container">
        {GAME_PLAYS.map((gamePlay) => (
          <GamePlayRow key={gamePlay.id} info={gamePlay} />
        ))}
        <Box className="ah-game-play-action-box">
          <Box
            component="button"
            className="play-now-btn-container orange-bg-shadow"
          >
            <img
              src={AHAssets.AHBtnLeftDecor}
              loading="lazy"
              alt=""
              className="btn-decor-img"
            />
            <Typography className="play-now-btn-text">Play Now</Typography>
            <img
              src={AHAssets.AHBtnRightDecor}
              loading="lazy"
              alt=""
              className="btn-decor-img"
            />
          </Box>
        </Box>
        <Box className="ah-game-play-overlay-box" />
      </Box>
      <Box className="ah-game-play-img-container" sx={{}}>

        <Box component="img"
          src={AHAssets.AHGamePlay}
          loading="lazy"
          alt=""
          className="ah-game-play-img"
        />

        <Box
          className="ah-game-play-video"
          sx={{
            // width: '100%',// Set width to 100% of its container
            // height: 'auto',         // Adjust height automatically
            maskImage: 'url(videos/game-play-image-mask.png)',  // Path to mask image
            WebkitMaskImage: 'url(videos/game-play-image-mask.png)',  // Safari support
            // maskSize: 'contain',      // Adjust mask size to cover the video
            // WebkitMaskSize: 'contain',
            maskRepeat: 'no-repeat', // Don't repeat the mask image
            WebkitMaskRepeat: 'no-repeat',
            maskPosition: 'center',  // Center the mask on the video
            WebkitMaskPosition: 'center',
            overflow: 'hidden',     // Ensures the content stays within the masked area
            position: `absolute`,
            objectFit: `contain`
          }}
        >
          <video autoPlay loop muted playsInline style={{ width: '100%', height: '100%' }}>
            <source src="videos/mode_1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>

      </Box>
    </Box>
  );
};

export default AHGamePlay1;
