import React, { useState, useEffect, useRef } from "react";
import { Drawer, Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import "./VideoDrawer.css";

const VideoDrawer = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false); // State for dialog
  const [isMuted, setIsMuted] = useState(true); // State to handle mute/unmute
  const videoRef = useRef(null); // Reference to the video element
  const timeoutRef = useRef(null); // Reference for the timeout

  useEffect(() => {
    // Check if the drawer has already been shown using localStorage
    const hasVideoPlayed = localStorage.getItem("hasVideoPlayed");

    if (!hasVideoPlayed) {
      setDrawerOpen(true);
    }

    // Define the global function to open the drawer and start the video
    window.OpenVideoDrawer = () => {
      setDrawerOpen(true); // Open the drawer
      if (videoRef.current) {
        videoRef.current.currentTime = 0; // Start video from the beginning
        videoRef.current.play(); // Play the video
      }
    };

    // Cleanup the global function on unmount
    return () => {
      delete window.OpenVideoDrawer;
    };
  }, []);

  const handleClose = () => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setDrawerOpen(false);
    setDialogOpen(false);
    // Store in localStorage that the video has been shown
    localStorage.setItem("hasVideoPlayed", "true");
  };

  const handleVideoEnd = () => {
    // Instead of closing automatically, open the dialog to ask the user
    setDialogOpen(true);

    // Auto-close the dialog after 5 seconds if no action is taken
    timeoutRef.current = setTimeout(() => {
      handleClose();
    }, 5000); // 5 seconds
  };

  const toggleMute = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const handlePlayAgain = () => {
    // Clear the timeout if user clicks Play Again
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setDialogOpen(false);
    // Restart the video
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  return (
    <div>
      {/* Drawer containing the video */}
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleClose}>
        <Box
          sx={{
            width: "100vw", // Full-screen width
            height: "100vh", // Full-screen height
            position: "relative",
          }}
        >
          {/* Close Button */}
          <Button
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10, // Adjusted to make space for unmute button
              zIndex: 10,
              backgroundColor: "white",
              color: "black",
              padding: "5px 10px",
              fontWeight: "bold",
              fontSize: "16px",
              borderRadius: "5px",
            }}
          >
            [X]
          </Button>

          {/* Unmute Button */}
          {isMuted? <>
            <Button
            onClick={toggleMute}
            sx={{
              position: "absolute",
              top: 10,
              right: 90, // Place next to the close button
              zIndex: 10,
              backgroundColor: "white",
              color: "black",
              padding: "5px 10px",
              fontWeight: "bold",
              fontSize: "16px",
              borderRadius: "5px",
            }}
          >
            {isMuted ? "Unmute" : "Mute"}
          </Button>
          </>: <></>}

          {/* Video element */}
          <video
            className="drawer-video"
            controls
            autoPlay
            loop={false} // Ensure the video doesn't loop
            muted={isMuted} // Control mute/unmute state
            onEnded={handleVideoEnd} // Open dialog when video ends
            ref={videoRef} // Assign the video element reference
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain", // Aspect ratio to contain the video in the page
            }}
          >
            <source src="videos/cinematic.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Drawer>

      {/* Dialog asking if the user wants to play again or close */}
      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogTitle>Play Again or Close?</DialogTitle>
        <DialogActions>
          <Button onClick={handlePlayAgain} color="primary">
            Play Again
          </Button>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VideoDrawer;
