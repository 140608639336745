import AHLogo from "./ah/logo.png";
import AHPlay from "./ah/play.svg";
import AHHero1 from "./ah/hero1.png"
import AHHero2Large from "./ah/hero2_large.png";
import AHHero3 from "./ah/hero3.png";
import AHHero5 from "./ah/hero5_large.png";
import AHApple from "./ah/apple.svg";
import AHXIcon from "./ah/x-icon.svg";
import AHDocsIcon from "./ah/docs-icon.svg";
import AHGooglePlay from "./ah/google-play.svg";
import AHTrailerGame from "./ah/trailer-game.png";
import AHDiscordIcon from "./ah/discord-icon.svg";
import AHLinkedInIcon from "./ah/linkedin-icon.svg";
import AHBtnLeftDecor from "./ah/btn-left-decor.svg";
import AHBtnRightDecor from "./ah/btn-right-decor.svg";
import AHPointer from "./ah/pointer.png";
import AHGamePlay from "./ah/game-play-image.png";
import AHGamePlayMask from "./ah/game-play-image-mask.png";
import AHAvatar1 from "./ah/avatar1.png";
import AHAvatar2 from "./ah/avatar2.png";
import AHAvatar3 from "./ah/avatar3.png";
import AHAvatar4 from "./ah/avatar4.png";
import AHAvatar5 from "./ah/avatar5.png";
import AHAvatar6 from "./ah/avatar6.png";
import AHArgosCharacter from "./ah/ah-argos-character.png";
import AHSwiftCharacter from "./ah/ah-swift-character.png";
import AHVixenCharacter from "./ah/ah-vixen-character.png";
import AHKatherineCharacter from "./ah/ah-katherine-character.png";
import AHGameInfoCharacter from "./ah/archer-game-info-character.png";
import AHHamburger from "./ah/hamburger.svg";
import AHNftCardCharacter1 from "./ah/nft-card-character-1.png";
import AHNftCardCharacter2 from "./ah/nft-card-character-2.png";
import AHPlusIcon from "./ah/plus-icon.png";
import AHNikaPoint from "./ah/nika-point.png";
import AHAcherHero1 from "./ah/archer-hero-1.png";
import AHNikaPointBg from "./ah/nika-point-bg.png";
import AHArcherHeroCardBg from "./ah/archer-hero-card-bg.png";
import AHNikaLogo from "./ah/nika-logo.png";
import AHSeiLogo from "./ah/sei-logo.png";
import AHAlloraLogo from "./ah/allora-logo.png";
import AHMediumIcon from "./ah/median-icon.png";
import AHVixenPreview from "./ah/vixen_preview.png";
import AHKatherinePreview from "./ah/katherine_preview.png";
import AHArgosPreview from "./ah/argos_preview.png";
import AHSwiftPreview from "./ah/swift_preview.png";
import AHHero4Large from "./ah/hero4_large.png";
import AHPlayIcon from "./ah/play-icon.png";

const AHAssets = {
  AHPlayIcon,
  AHHero4Large,
  AHHero2Large,
  AHMediumIcon,
  AHLogo,
  AHPlay,
  AHHero1,
  AHHero3,
  AHHero5,
  AHXIcon,
  AHApple,
  AHAvatar1,
  AHAvatar2,
  AHAvatar3,
  AHAvatar4,
  AHAvatar5,
  AHAvatar6,
  AHPointer,
  AHDocsIcon,
  AHPlusIcon,
  AHNikaPoint,
  AHGamePlay,
  AHGamePlayMask,
  AHSeiLogo,
  AHNikaLogo,
  AHAlloraLogo,
  AHAcherHero1,
  AHHamburger,
  AHGooglePlay,
  AHDiscordIcon,
  AHTrailerGame,
  AHLinkedInIcon,
  AHBtnLeftDecor,
  AHNikaPointBg,
  AHBtnRightDecor,
  AHArgosCharacter,
  AHSwiftCharacter,
  AHVixenCharacter,
  AHVixenPreview,
  AHArgosPreview,
  AHSwiftPreview,
  AHKatherinePreview,
  AHArcherHeroCardBg,
  AHGameInfoCharacter,
  AHNftCardCharacter1,
  AHNftCardCharacter2,
  AHKatherineCharacter,
};

export { AHAssets };
