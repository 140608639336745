// ** MUI import
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";

// ** Components import
import AHAppBarMenu from "./AHAppbarMenu";
import LoginButton from "../../components/login-btn";
import ConnectButton from "../../components/connect-btn";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const GitBookUrl = "https://docs.archerhunter.io"

export const AppBarItems = [
  {
    id: "ah-home",
    label: "Home",
  },
  {
    id: "ah-documents",
    label: "Document",
  },
  {
    id: "ah-gallery-section",
    label: "Gallery",
  },
  {
    id: "ah-download-section",
    label: "Download",
  },
];

const AHAppBar = () => {
  const scrollToSection = (sectionId: string) => {
    if(sectionId === 'ah-documents') {
      window.open(GitBookUrl, "_blank")
      return;
    }
    
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
    }
  };
  return (
    <AppBar className="ah-app-bar" elevation={0}>
      <Box className="ah-app-bar-content">
        <img src={AHAssets.AHLogo} alt="" className="ah-app-logo" />
        <Box className="ah-app-bar-items-container">
          {AppBarItems.map((item) => (
            <Box
              component="div"
              className="ah-app-bar-item"
              key={item.id}
              onClick={() => scrollToSection(item.id)}
            >
              <Typography className="ah-app-bar-label">{item.label}</Typography>
            </Box>
          ))}
        </Box>
        <Box className="ah-app-bar-action-container">
          <LoginButton />
          <ConnectButton />
        </Box>
        <AHAppBarMenu />
      </Box>
    </AppBar>
  );
};

export default AHAppBar;
