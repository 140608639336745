// ** MUI import
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const SOCIAL_LINKS = [
  {
    id: "ah-linkedin-ref",
    url: "https://www.linkedin.com/company/nikalabs",
    icon: AHAssets.AHLinkedInIcon,
  },
  {
    id: "ah-discord-ref",
    url: "https://discord.com/invite/R2DfvVXWzV",
    icon: AHAssets.AHDiscordIcon,
  },
  {
    id: "ah-x-ref",
    url: "https://x.com/archerhunter_HQ ",
    icon: AHAssets.AHXIcon,
  },
  {
    id: "ah-docs-ref",
    url: "https://nikalabs.gitbook.io/archer-hunter",
    icon: AHAssets.AHDocsIcon,
  },
  {
    id: "ah-medium-ref",
    url: "#",
    icon: AHAssets.AHMediumIcon,
  },
];

const AHFooter = () => {
  return (
    <Box className="ah-footer-container">
      <Box className="ah-footer-info-box">
        <Typography className="ah-footer-title">Stay updated</Typography>
        <Typography className="ah-footer-description">
          By staying updated, you'll be the first to know about new events,
          updates, and exciting developments in the world of Archer Hunter. Join
          our community and never miss a beat!
        </Typography>
      </Box>
      <Box className="ah-footer-social-links-box">
        {SOCIAL_LINKS.map((link) => (
          <Box
            key={link.id}
            component={Link}
            href={link.url}
            target="_blank"
            className="ah-footer-social-link-box"
          >
            <img
              key={link.id}
              src={link.icon}
              alt=""
              className="footer-social-link-icon"
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AHFooter;
