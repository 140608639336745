// ** MUI import
import Box from "@mui/material/Box";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const Hero3Slice = () => {
  return (
    <Box className="ah-hero-slice-container">
      <img src={AHAssets.AHHero3} className="hero3-slice-img" alt=""/>
    </Box>
  );
};

export default Hero3Slice;
