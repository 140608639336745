// ** Swiper components import
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// ** Swiper css import
import "swiper/css";

// ** Hero slice import
import Hero1Slice from "./Hero1Slice";
import Hero2Slice from "./Hero2Slice";
import Hero3Slice from "./Hero3Slice";
import Hero4Slice from "./Hero4Slice";
import Hero5Slice from "./Hero5Slice";

// Css import
import "./styles.css";

const HeroesSlice = () => {
  return (
    <Swiper
      loop={true}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      <SwiperSlide>
        <Hero1Slice />
      </SwiperSlide>
      <SwiperSlide>
        <Hero2Slice />
      </SwiperSlide>
      <SwiperSlide>
        <Hero3Slice />
      </SwiperSlide>
      <SwiperSlide>
        <Hero4Slice />
      </SwiperSlide>
      <SwiperSlide>
        <Hero5Slice />
      </SwiperSlide>
    </Swiper>
  );
};

export default HeroesSlice;
