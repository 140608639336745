// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import HeroesSlice from "../../components/hero-slices";
import GameTrailer from "../../components/game-trailer";
import SocialLinksBox from "../../components/social-links-box";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const AHHeroSection = () => {
  return (
    <Box className="hero-section-container">
      {/* section text info */}
      <Box className="hero-section-left-side-content">
        <Box className="hero-section-left-side-info">
          <Box>
            <Typography className="hero-section-main-title fadeInUp-animation">
              The Ultimate Archery
            </Typography>
            <Typography className="hero-section-main-title fadeInUp-animation">
              Conquer the World
            </Typography>
          </Box>
          <Typography className="hero-section-sub-title fadeInUp-animation">
            With an immersive experience and endless possibilities, engage in
            thrilling combat as you face off against hordes of enemies, using
            your archery skills to defeat fearsome monsters.
          </Typography>
          <Box
            component="button"
            className="pre-register-btn-container orange-bg-shadow fadeInUp-animation"
          >
            <img
              src={AHAssets.AHBtnLeftDecor}
              loading="lazy"
              alt=""
              className="btn-decor-img"
            />
            <Typography className="pre-register-btn-text">
              Pre-Register
            </Typography>
            <img
              src={AHAssets.AHBtnRightDecor}
              loading="lazy"
              alt=""
              className="btn-decor-img "
            />
          </Box>
        </Box>
        <Box className="hero-section-download-link-container fadeInUp-animation">
          <Typography className="download-link-title">Available on</Typography>
          <Box className="download-link-container">
            <Box
              component="div"
              className="download-link-btn"
            >
              <img
                src={AHAssets.AHGooglePlay}
                alt=""
                className="download-link-btn-icon"
              />
              <Typography className="download-link-btn-text">
                Google play
              </Typography>
            </Box>
            <Box className="download-link-btn">
              <img
                src={AHAssets.AHApple}
                alt=""
                className="download-link-btn-icon"
              />
              <Typography className="download-link-btn-text">
                App Store
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* section image info */}
      <HeroesSlice />
      <Box className="game-trailer-wrapper">
        <GameTrailer />
      </Box>
      <Box className="social-link-wrapper">
        <SocialLinksBox />
      </Box>
    </Box>
  );
};

export default AHHeroSection;
