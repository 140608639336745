// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Assert import
import { AHAssets } from "../../assets";

import BgImage from "./images/character-slice-bg.png";

// ** Css import
import "./styles.css";

export interface CharacterInfo {
  name: string;
  image: string;
  description: string;
  imgClass?: string;
  prevImage?: {
    src: string;
    imgClass?: string;
  };
  nextImage?: {
    src: string,
    imgClass?: string;
  };
}

type CharacterSliceProps = {
  info: CharacterInfo;
};

const CharacterSlice = ({ info }: CharacterSliceProps) => {
  return (
    <Box className="character-info-slice">
      <img src={BgImage} loading="lazy" alt="" className='character-bg-img' />
      <Box className="character-info-box">
        <Typography className="character-name">{info.name}</Typography>
        <Box className="character-description-box">
          <Typography component="p" className="character-description">
            {info.description}
          </Typography>
        </Box>
        <Box
          component="button"
          className="learn-more-btn-container orange-bg-shadow"
        >
          <img
            src={AHAssets.AHBtnLeftDecor}
            loading="lazy"
            className="btn-decor-img"
            alt=""
          />
          <Typography className="learn-more-btn-text">Learn More</Typography>
          <img
            src={AHAssets.AHBtnRightDecor}
            loading="lazy"
            className="btn-decor-img"
            alt=""
          />
        </Box>
      </Box>
      <img
        src={info.image}
        loading="lazy"
        alt=""
        className={info.imgClass ? info.imgClass : "character-image"}
      />
    </Box>
  );
};

export default CharacterSlice;
