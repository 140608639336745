// ** React import
import { useState, Fragment } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import VideoDialog from "./VideDialog";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const GameTrailer = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenVideo = () => {
    // setOpenDialog(true);
    // @ts-ignore
    window.OpenVideoDrawer();
  };

  // const handleCloseVideo = () => {
  //   setOpenDialog(false);
  // };

  return (
    <Fragment>
      {/* <VideoDialog open={openDialog} handleClose={handleCloseVideo} /> */}
      <Box
        component="div"
        className="game-trailer-container"
        onClick={handleOpenVideo}
      >
        <Box className="game-trailer-box">
          <img
            src={AHAssets.AHTrailerGame}
            className="trailer-game-img"
            alt=""
          />
          <Box className="watch-game-trailer">
            <img
              src={AHAssets.AHPlay}
              className="trailer-game-play-icon"
              alt=""
            />
            <Typography className="watch-trailer-text">
              Watch trailer
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        component="div"
        className="play-trailer-icon-box"
        onClick={handleOpenVideo}
      >
        <img alt="" src={AHAssets.AHPlayIcon} className="play-trailer-icon" />
      </Box>
    </Fragment>
  );
};

export default GameTrailer;
