// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const ArcherHeroCard = () => {
  return (
    <Box className="hero-card-container">
      <img src={AHAssets.AHArcherHeroCardBg} className="hero-card-bg-img" alt="" />
      <img src={AHAssets.AHNikaPoint} className="hero-card-nika-point-decor1" alt=""/>
      <img src={AHAssets.AHNikaPoint} className="hero-card-nika-point-decor2" alt="" />
      <Box className="hero-nika-point-box-container">
        <img src={AHAssets.AHNikaPointBg} className="hero-card-nika-point-img" alt=""/>
        <Typography className="hero-card-nika-point">10,000</Typography>
        <Box className="hero-card-plus-box">
          <img src={AHAssets.AHPlusIcon} className="hero-card-plus-icon" alt=""/>
        </Box>
      </Box>
      <img src={AHAssets.AHAcherHero1} className="hero-card-hero-img" alt=""/>
    </Box>
  );
};

export default ArcherHeroCard;
